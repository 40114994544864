<template>
  <div id="app">
    <el-container>
      <el-header class="top">
        <el-row type="flex" justify="space-between" align="middle" style="height: 100%">
          <el-col>
            <el-row type="flex" justify="start">
              <el-col style="width:95px">
                <el-image style="height: 45px" src="images/logo.png"></el-image>
              </el-col>
              <el-col>
                <el-row>
                  <span style="font-size: 18px; font-weight: bold">PodAPI</span>
                </el-row>
                <el-row>
                  <el-dropdown trigger="click" @command="changeChannel">
                    <span style="cursor: pointer; color: #ffffff7f;">
                      {{ currentChannel ? currentChannel.info.title : "频道选择" }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-for="channel in channels" :key="channel.id" :command="channel.id">
                        {{ channel.info.title }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
          <el-col>
            <div style="float: right">
              <el-button type="primary" size="small" :loading="saving" @click="save">保存</el-button>
              <el-button type="danger" size="small" :loading="publishing" @click="publishRSS">发布</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside class="left">
          <el-menu :default-active="$route.fullPath" :default-openeds="openMenu" router>
            <el-menu-item index="/"><i class="el-icon-setting"></i>首页</el-menu-item>
            <el-menu-item index="/channel"><i class="el-icon-mic"></i>频道信息</el-menu-item>
            <el-menu-item index="/dataCenter"><i class="el-icon-data-line"></i>数据中心</el-menu-item>
            <el-submenu index="/episodes" v-loading="loadingEpisodes" element-loading-background="rgba(0, 0, 0, 0.5)">
              <template slot="title">
                <i class="el-icon-document-copy"></i>
                <span>节目</span>
              </template>
              <el-menu-item @click="addNewEpisode" class="episodeCell">
                <i class="el-icon-plus"></i>
                <span>上新</span>
              </el-menu-item>
              <el-menu-item :index="'/episode/' + episode.id" v-for="episode in episodes" :key="episode.id" class="episodeCell">
                <p class="episodeCellParagraph">{{ episode.info.title }}</p>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main class="main">
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
    <el-dialog :visible.sync="shouldShowLogin" fullscreen :show-close="false" top="0" custom-class="loginBackground">
      <div slot="title" style="font-size: 20px; color: #FAFAFA; font-weight: bold">
        PodAPI Studio
      </div>
      <div class="loginDialog">
        <el-form style="width: 300px">
          <el-form-item class="loginItem">
            <el-image style="width: 192px" src="images/logo.png"></el-image>
          </el-form-item>
          <el-form-item  class="loginItem">
            <el-input placeholder="请输入手机号" v-model.number="login.telphoneNumber" maxlength="12" type="tel" id="telephone">
              <el-select v-model="login.countryCode" slot="prepend" style="width:80px">
                <el-option label="中国" value="+86"></el-option>
                <el-option label="美国" value="+1"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item class="loginItem">
            <el-input placeholder="请输入验证码" v-model="login.verificationCode" maxlength="6" id="verifyCode" inputmode="decimal">
              <el-button slot="append" @click="sentAuthCode" :loading="login.sending" :disabled="login.verificationCountDown > 0 || login.telphoneNumber.length === 0">发送</el-button>
            </el-input>
          </el-form-item>
          <el-form-item class="loginItem">
            <el-button type="primary" @click="startLogin" style="width: 200px;" :loading="login.verifying" :disabled="login.telphoneNumber.length === 0 || login.verificationCode.length === 0">登录</el-button>
          </el-form-item>
        </el-form>
        <p style="text-align: center">
          <span class="beian">注册登录代表您同意相关用户协议</span>
          <br/>
          <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">陕ICP备2020018098号-1</a>
          <br/>
          <a class="beian" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011202000389" target="_blank">陕公网安备 61011202000389号</a>
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { Message } from 'element-ui';
import { MessageBox } from 'element-ui';
import { nanoid } from 'nanoid';
import { DateTime } from 'luxon';

export default {
  name: 'app',
  data() {
    return {
      openMenu: ['/episodes'],
      login: {
        telphoneNumber: '',
        countryCode: '+86',
        verificationCode: '',
        verificationCountDown: 0,
        sending: false,
        verifying: false
      },
    }
  },
  computed: {
    ...mapFields([
      'channels',
      'episodes',
      'selectedChannelId',
      'loadingEpisodes',
      'saving',
      'publishing',
      'user'
    ]),
    currentChannel() {
      return this.channels.find(ch => ch.id === this.selectedChannelId);
    },
    shouldShowLogin: function() {
      return this.$store.state.jwt === null;
    }
  },
  mounted() {
    if (this.$store.state.jwt) {
      this.$store.dispatch("getChannels");
    } else {
      this.loginDialogShown = true;
    }
    window.addEventListener('beforeunload', (event) => {
      const somethingChanged = this.$store.getters.channelsChanged || this.$store.getters.episodesChanged;
      if (somethingChanged) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = 'something changed';
      }
    });
  },
  methods: {
    // 发送验证码
    sentAuthCode() {
      if (this.login.telphoneNumber.length === 0) {
        return;
      }
      this.login.sending = true;
      this.$store.dispatch("sendRequest", {method: "sendAuthCode", params: {tel: this.login.telphoneNumber, country: this.login.countryCode}}).then(response => {
        Message.success("发送成功");
        this.login.verificationCountDown = 60;
        const timer = setInterval(() => {
          this.login.verificationCountDown--;
          if (this.login.verificationCountDown === 0) {
            clearInterval(timer);
          }
        }, 1000);
      }).finally(() => {
        this.login.sending = false;
      });
    },
    // 登录
    startLogin() {
      if (this.login.telphoneNumber.length === 0 || this.login.countryCode === 0 || this.login.verificationCode === 0) {
        return;
      }
      this.login.verifying = true;
      this.$store.dispatch("sendRequest", {method: "loginAuthCode", params: {tel: this.login.telphoneNumber, country: this.login.countryCode, code: this.login.verificationCode}}).then(response => {
        this.$store.commit("jwt", response.jwt);
        this.$store.commit("user", response.user);
        this.$store.dispatch("getChannels");
        this.loginDialogShown = false;
      }).finally(() => {
        this.login.verifying = false;
      });
    },
    save() {
      this.$store.dispatch("saveEverything");
    },
    publishRSS() {
      if (this.user.publish_limit) {
        Message.error("您的账号还不能发布RSS，请联系我们");
        return;
      }
      MessageBox.confirm("是否真的要保存并更新RSS，很快就会有人抓取到你的改动", "发布确认").then(response => {
        if (response === 'confirm') {
          if (this.$store.getters.episodesChanged || this.$store.getters.channelsChanged) {
            this.$store.dispatch("saveEverything").then(() => {
              this.$store.dispatch("publishRSS");
            });
          } else {
            this.$store.dispatch("publishRSS");
          }
        }
      });
    },
    addNewEpisode() {
      MessageBox.prompt("起个名字吧", "创建新节目").then(response => {
        const name = response.value;
        const identifier = nanoid();
        const episode = {
          id: identifier, 
          info: {
            title: name.trim(), 
            cover: this.currentChannel.info.cover,
            pubDate: DateTime.now().toRFC2822(),
            guid: identifier
          }
        };
        this.episodes.unshift(episode);
        this.goToEpisodeInfo(episode);
      });
    },
    goToEpisodeInfo(episodeInfo) {
      this.$router.push("/episode/" + episodeInfo.id);
    },
    changeChannel(channelID) {
      if (this.$store.getters.episodesChanged) {
        MessageBox.confirm("有节目信息未保存，是否继续？", "未保存").then(response => {
          if (response === 'confirm') {
            this.selectedChannelId = channelID;
            this.$store.dispatch("getEpisodesOfCurrentChannel");
            this.$router.push("/");
          }
        });
      } else {
        this.selectedChannelId = channelID;
        this.$store.dispatch("getEpisodesOfCurrentChannel");
        this.$router.push("/");
      }
    },
  }
}
</script>

<style>
  :root {
    --left-width: 320px;
  }
  @media only screen and (max-width: 650px) {
    :root {
      --left-width: 150px;
    }
  }
  
  ::-webkit-scrollbar {
      width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
      -webkit-border-radius: 5px;
      border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      opacity:0.1;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(0,0,0,0.5); 
  }
  
  body {
    margin: 0;
    width: 100%;
    position: fixed;
    overflow: hidden;
    overscroll-behavior-y: none;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  }
  .top {
    background-color: #272f3f;
    color: white;
  }
  .left {
    background-color: #1e222d;
    height: calc(100vh - 60px);
    width: var(--left-width) !important;
  }
  .main {
    background-color: #E9EEF3;
    color: #333;
    height: calc(100vh - 60px);
  }
  .loginItem {
    text-align: center;
  }
  .episodeCell {
    min-width: var(--left-width) !important;
    padding: 5px 5px 5px 30px !important;
  }
  .episodeCellParagraph {
    line-height: 15px !important;
    font-size: 14px !important;
    color:#cdcdcd !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-menu {
    border: 0px !important;
    overflow-x: hidden;
  }
  .beian {
    font-size: 12px;
    color:#888888;
    text-decoration: none;
  }
  .loginDialog {
    display: flex; justify-content: center; align-items: center; flex-direction: column; height: 80vh;
  }
  .loginBackground {
    aspect-ratio: 900 / 600;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    background-image: url('../public/images/wave.svg') !important;
  }
</style>

