import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';
import axios from 'axios'
import BMF from 'browser-md5-file';
import COS from 'cos-js-sdk-v5';
import equal from 'deep-equal';
import { Loading } from 'element-ui';
import { Message } from 'element-ui';
import { DateTime } from 'luxon';

Vue.use(Vuex);

var bucketName = 'host-1302620550';
var bucketRegion = 'ap-guangzhou';
var loadingInstance = null;

const cos = new COS({
  getAuthorization: function (options, callback) {
      // 异步获取临时密钥
      store.dispatch("sendRequest", {method: "sts"}).then(response => {
        bucketName = response.bucket;
        bucketRegion = response.region;
        callback({
          TmpSecretId: response.Credentials.TmpSecretId,
          TmpSecretKey: response.Credentials.TmpSecretKey,
          XCosSecurityToken: response.Credentials.Token,
          //StartTime: response.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: response.ExpiredTime, // 时间戳，单位秒，如：1580000900
        });
      })
  },
  ProgressInterval: 500
});

const store = new Vuex.Store({
  state: {
    jwt: localStorage.getItem("jwt"),
    user: null,
    cdn: "https://host.podapi.xyz/",
    cgi: "https://cgi.podapi.xyz/host",
    channels: [],
    episodes: [],
    channelsCopy: [],
    episodesCopy: [],
    imageFiles: [],
    mediaFiles: [],
    selectedChannelId: null,
    loadingEpisodes: false,
    saving: false,
    publishing: false,
    uploadProgress: 0,
    playerCurrentTime: 0
  },

  getters: {
    // Add the `getField` getter to the
    // `getters` of your Vuex store instance.
    getField,
    currentChannel(state) {
      return state.channels.find(ch => ch.id === state.selectedChannelId);
    },
    channelsChanged(state) {
      return !equal(state.channels, state.channelsCopy);
    },
    episodesChanged(state) {
      return !equal(state.episodes, state.episodesCopy);
    }
  },

  mutations: {
    // Add the `updateField` mutation to the
    // `mutations` of your Vuex store instance.
    updateField,
    jwt(state, jwt) {
      if (jwt === null) {
        state.channels = [];
        state.episodes = [];
        state.user = null;
        state.jwt = null;
        localStorage.removeItem("jwt");
      } else {
        state.jwt = jwt;
        localStorage.setItem("jwt", jwt);
      }
    },
  },

  actions: {
    getUserInfo(context) {
      if (context.state.jwt) {
        context.dispatch("sendRequest", {method: "getUserInfo", params: {}}).then(response => {
          context.state.user = response;
        });
      }
    },
    getChannels(context) {
      if (context.state.jwt) {
        loadingInstance = Loading.service({text: "获取频道信息...", background:"#FFFFFF2A"});
        context.dispatch("sendRequest", {method: "getChannels", params: {}}).then(response => {
          if (Array.isArray(response)) {
            context.state.channels = response;
            context.state.channelsCopy = JSON.parse(JSON.stringify(response));
            if (!context.state.selectedChannelId) {
              if (context.state.channels.length) {
                context.state.selectedChannelId = context.state.channels[0].id;
              } else {
                context.state.selectedChannelId = null;
              }
            }
            context.dispatch("getEpisodesOfCurrentChannel");
            context.dispatch("getUserInfo");
          } else {
            loadingInstance.close();
          }
        }).catch((error) => {
          loadingInstance.close();
        });
      }
    },
    getEpisodesOfCurrentChannel(context) {
      if (context.state.jwt && context.state.selectedChannelId) {
        context.state.loadingEpisodes = true;
        context.dispatch("sendRequest", {method: "getEpisodes", params: {channel: context.state.selectedChannelId}}).then(response => {
          context.state.episodes = response;
          context.state.episodesCopy = JSON.parse(JSON.stringify(response));
        }).finally(() => {
          loadingInstance.close();
          context.state.loadingEpisodes = false;
          context.dispatch("getFiles");
        });
      } else {
        context.state.episodes = [];
        context.state.episodesCopy = [];
        loadingInstance.close();
        Message.info("没有当前频道");
      }
    },
    // 上传文件
    upload(context, {file, folder, episodeID}) {
      return new Promise((resolve, reject) => {
        if (file) {
          const bmf = new BMF();
          console.log("md5 start");
          bmf.md5(file, (error, hash) => {
            if (hash) {
              //console.log(hash);
              context.state.uploadProgress = 0.1;
              const filename = hash + "." + file.name.split('.').pop();
              const key = context.getters.currentChannel.id + "/" + folder + "/" + filename;
              //console.log(bucketRegion);
              cos.putObject({
                Bucket: bucketName,
                Region: bucketRegion,
                Key: key,
                StorageClass: 'STANDARD',
                Body: file,
                onProgress: function(progressData) {
                  console.log(progressData);
                  //progress.setText("速度: " + Math.floor(progressData.speed / 1000) + "KB/s");
                  context.state.uploadProgress = progressData.percent * 100;
                }
              }, function(error, data) {
                context.state.uploadProgress = 0;
                if (error) {
                  console.error(error);
                  reject(error);
                } else {
                  console.log(data);
                  if (data.statusCode === 200) {
                    if (folder === "images") {
                      resolve(context.state.cdn + key);
                      context.dispatch("getFiles");
                    } else {
                      resolve(context.state.cdn + key + "?ep=" + episodeID);
                    }
                  }
                }
              });
            } else {
              reject("MD5错误");
            }
          });
        } else {
          reject("缺少文件");
        }
      });
    },
    getFiles(context) {
      // cos.getBucket({
      //     Bucket: 'host-1302620550',
      //     Region: 'ap-guangzhou',
      //     Prefix: context.state.selectedChannelId + "/images/"
      //   },
      //   (error, data) => {
      //     //console.log(data);
      //     if (data && Array.isArray(data.Contents)) {
      //       context.state.imageFiles = data.Contents;
      //     }
      //   });
    },
    saveEverything(context) {
      return new Promise((resolve, reject) => {
        if (context.state.jwt && context.getters.currentChannel) {
          //loadingInstance = Loading.service({text: "保存中，请稍候..."});
          context.state.saving = true;
          context.dispatch("sendRequest", {method: "saveEverything", params: {channel: context.getters.currentChannel, episodes: context.state.episodes}}).then(response => {
            Message.success("保存成功");
            context.state.channelsCopy = JSON.parse(JSON.stringify(context.state.channels));
            context.state.episodesCopy = JSON.parse(JSON.stringify(context.state.episodes));
            context.getters.currentChannel.last_updated = DateTime.now().toSQL({ includeOffset: false });
            resolve();
          }).catch(error => {
            reject(error);
          }).finally(() => {
            //loadingInstance.close();
            context.state.saving = false;
          });
        } else {
          reject("没有登录");
        }
      });
    },
    publishRSS(context) {
      if (context.state.jwt && context.getters.currentChannel) {
        //loadingInstance = Loading.service({text: "发布中，请稍候..."});
        context.state.publishing = true;
        context.dispatch("sendRequest", {method: "publish", params: {channelId: context.state.selectedChannelId}}).then(response => {
          axios.post("https://cgi.podapi.xyz/addFeed", {url: response});
          context.getters.currentChannel.last_published = DateTime.now().toSQL({ includeOffset: false });
          Message.success("发布成功");
        }).finally(() => {
          //loadingInstance.close();
          context.state.publishing = false;
        });
      }
    },
    // 发送请求
    sendRequest(context, {method, params}) {
      let requestData = {method, params};
      let headers = {'Content-Type': 'application/json'};
      if (context.state.jwt) {
        headers.Authorization = "Bearer " + context.state.jwt;
      }
      return new Promise((resolve, reject) => {
        let url = context.state.cgi;
        if (method === "sts") {
          url += "/sts";
        } else if (method === "publish") {
          url += "/publish";
        } else if (method === "import") {
          url += "/importFeed";
        }
        axios.post(url, requestData, {headers: headers}).then(
          response => {
            const responseData = response.data;
            //console.log(responseData);
            if (responseData.ret === 0) {
              resolve(responseData.data);
            } else {
              if (responseData.msg || responseData.errorMessage) {
                Message.error(responseData.msg || responseData.errorMessage);
              }
              if (responseData.ret === -1000) {
                // logout
                context.commit("jwt", null);
              }
              reject(responseData.msg || responseData.errorMessage);
            }
          }
        ).catch(error => {
          console.log(error.message);
          Message.error(error.message);
          reject(error.message);
        });
      });
    }
  },
});

export default store;