<template>
  <div>
    <el-form style="text-align:center">
      <p>欢迎使用PodAPI播客托管服务</p>
      <p>在这里你可以建立自己的播客频道，上传节目，生成RSS，让各种播客平台抓取到你的精美节目。</p>
      <el-form-item>
        <el-button @click="shouldShowCreateDialog=true">新的故事</el-button>
        <el-button @click="shouldShowImportDialog=true">旧的回忆</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-form v-if="currentChannel" label-position="right" label-width="80px">
      <h1 style="text-align:center">{{currentChannel.info.title}}</h1>
      <el-form-item label="RSS">
        <el-input :value="rssLink" disabled>
          <el-button slot="append" icon="el-icon-copy-document" @click="copyRSSLink">复制</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="播放器">
        <el-input :value="website" disabled>
          <el-button slot="append" icon="el-icon-thumb" @click="openWebsite">访问</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="上次保存">
        <span>{{getDateString(currentChannel.last_updated)}}</span>
      </el-form-item>
      <el-form-item label="上次发布">
        <span>{{getDateString(currentChannel.last_published)}}</span>
      </el-form-item>
      <el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col style="width: 150px">
            <el-button icon="el-icon-user" type="warning" @click="logout" plain>登出账号</el-button>
          </el-col>
          <el-col style="width: 150px">
            <el-button icon="el-icon-delete" type="danger" @click="deleteChannel" :loading="deleting" plain>删除频道</el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
    <el-dialog title="创建一个新的频道" :visible.sync="shouldShowCreateDialog">
      <el-form>
        <el-form-item label="频道名称">
          <el-input placeholder="给新频道起一个能火起来的名字吧" v-model="userInputChannelName"></el-input>
        </el-form-item>
        <el-form-item label="频道ID(您只有这一次机会给频道一个特别的ID，这会影响到URL的样子)">
          <el-input placeholder="必须是8-32位的字母或数字" v-model="userInputChannelID"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="createNewChannel">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="导入已有的频道" :visible.sync="shouldShowImportDialog">
      <el-form>
        <el-form-item label="RSS">
          <el-input placeholder="你要导入的频道RSS链接地址" v-model="userInputRSSURL"></el-input>
        </el-form-item>
        <el-form-item label="频道ID(您只有这一次机会给频道一个特别的ID，这会影响到URL的样子)">
          <el-input placeholder="必须是8-32位的字母或数字" v-model="userInputChannelID"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importChannel">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { MessageBox } from 'element-ui';
import { Message } from 'element-ui';
import { DateTime } from 'luxon';
import copy from 'copy-text-to-clipboard';
import axios from 'axios';
import { nanoid } from 'nanoid';

export default {
  name: 'Home',
  data() {
    return {
      deleting: false,
      userInputChannelName: '',
      userInputChannelID: nanoid(),
      userInputRSSURL: '',
      shouldShowCreateDialog: false,
      shouldShowImportDialog: false
    }
  },
  computed: {
    ...mapFields([
      'channels',
      'selectedChannelId',
      'user'
    ]),
    currentChannel() {
      return this.channels.find(ch => ch.id === this.selectedChannelId);
    },
    website() {
      return 'https://host.podapi.xyz/' + this.selectedChannelId + "/";
    },
    rssLink() {
      if (this.currentChannel.url) {
        return this.currentChannel.url;
      } else {
        return "还没有";
      }
    }
  },
  methods: {
    deleteChannel() {
      MessageBox.confirm("真的要删除频道【" + this.currentChannel.info.title + "】吗？", "确认").then(response => {
        if (response === "confirm") {
          MessageBox.confirm("所有节目信息和频道信息都会不见了，删除以后无法恢复！", "再次确认").then(response => {
            if (response === "confirm") {
              this.deleting = true;
              this.$store.dispatch("sendRequest", {method: "deleteChannel", params: {channelId: this.selectedChannelId}}).then(response => {
                Message.success("删除成功");
                this.$store.dispatch("getChannels");
                const feedURL = this.cdn + this.selectedChannelId + '/feed.rss';
                axios.post("https://cgi.podapi.xyz/addFeed", {url: feedURL, disable: true});
              }).finally(() => {
                this.deleting = false;
              });
            }
          });
        }
      });
    },
    logout() {
      this.$store.commit("jwt", null);
    },
    openWebsite() {
      window.open(this.website);
    },
    copyRSSLink() {
      copy(this.rssLink);
      Message.success("已复制到剪切板");
    },
    getDateString(timestamp) {
      if (timestamp && timestamp.length) {
        return DateTime.fromSQL(timestamp).toRelative();
      } else {
        return "还没有";
      }
    },
    createNewChannel() {
      if (this.user) {
        if (this.channels.length >= this.user.channel_limit) {
          this.showError("已达到此账号频道数量上限");
          this.shouldShowCreateDialog = false;
          return;
        }
        this.userInputChannelName = this.userInputChannelName.trim();
        if (this.userInputChannelName.length) {
          this.userInputChannelID = this.userInputChannelID.trim();
          if (this.userInputChannelID.length >= 8 && this.userInputChannelID.length <= 32) {
            if (this.userInputChannelID.match(/^[a-zA-Z0-9-_]+$/) !== null) {
              const channelInfo = { 
                title: this.userInputChannelName,
                guid: this.userInputChannelID, 
                summary:"介绍一下你的频道吧", 
                description:"更详细的介绍一下你的频道",
                website:"https://www.podapi.xyz/channel.html?id=" + this.userInputChannelID,
                category:"Arts"
              };
              this.shouldShowCreateDialog = false;
              this.$store.dispatch("sendRequest", {method: "createChannel", params: {channel: channelInfo}}).then(response=> {
                this.selectedChannelId = response.id;
                this.$store.dispatch("getChannels");
              });
            } else {
              this.showError("ID必须是字母或数字");
            }
          } else {
            this.showError("ID必须是至少8位");
          }
        } else {
          this.showError("你还是不想火啊");
        }
      } else {
        this.shouldShowCreateDialog = false;
      }
    },
    importChannel() {
      if (this.user) {
        if (this.channels.length >= this.user.channel_limit) {
          this.showError("已达到此账号频道数量上限");
          this.shouldShowImportDialog = false;
          return;
        }
        this.userInputRSSURL = this.userInputRSSURL.trim();
        if (this.stringIsAValidUrl(this.userInputRSSURL)) {
          if (this.userInputChannelID.length >= 8 && this.userInputChannelID.length <= 32) {
            if (this.userInputChannelID.match(/^[a-zA-Z0-9-_]+$/) !== null) {
              this.shouldShowImportDialog = false;
              this.$store.dispatch("sendRequest", {method: "import", params: {channelId: this.userInputChannelID, feedURL: this.userInputRSSURL}}).then(response=> {
                this.$store.dispatch("getChannels");
              });
            } else {
              this.showError("ID必须是字母或数字");
            }
          } else {
            this.showError("ID必须是至少8位");
          }
        } else {
          this.showError("URL不合法");
        }
      } else {
        this.shouldShowImportDialog = false;
      }
    },
    showError(message) {
      Message.error(message);
    },
    stringIsAValidUrl(s) {
      try {
        new URL(s);
        return true;
      } catch (err) {
        return false;
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
